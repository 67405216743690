/*
*	FILE			:	RedditFavApp.js
*	PROJECT			:	SENG3080 - A1
*	PROGRAMMER		:	Andrew Gordon
*	DESCRIPTION		:
*		Contains the RedditFavApp component used to manage a list of favourite reddit posts
*/

import React, { useState, useEffect } from 'react';
import './RedditFavApp.css';
import PostItem from './PostItem'
import toast, { Toaster } from 'react-hot-toast';

/**
 * @description The RedditFavApp is used to manage a list of favourite subreddit posts
 * @param {Object} props - No props used
 * @returns {JSX.Element} - The component JSX.Element contains both the top 10 and list of favourites and all their associated controls
 */
function RedditFavApp() {
    // State declarations using useState hook
    const [postIds, setPosts] = useState([]);
    const [favouriteIds, setFavourites] = useState([]);

    // Default subreddit to load on component mount
    const defaultSubreddit = 'ProgrammerHumor';

    // useEffect hook for loading favouriteIds from local storage on component mount, setting default subreddit
    useEffect(() => {
        const storedFavouriteIds = localStorage.getItem('favouriteIds');
        if (storedFavouriteIds)
            setFavourites(JSON.parse(storedFavouriteIds));
        handleSubmit(null, defaultSubreddit);
        document.getElementById('subreddit').value = defaultSubreddit;
    }, []);

    // useEffect hook for updating local storage when postIds state changes
    useEffect(() => {
        localStorage.setItem('postIds', JSON.stringify(postIds));
    }, [postIds]);

    // useEffect hook for updating local storage when favouriteIds state changes
    useEffect(() => {
        localStorage.setItem('favouriteIds', JSON.stringify(favouriteIds));
    }, [favouriteIds]);

    // Function for handling form submission
    function handleSubmit(event, subreddit) {
        const subredditLogo = document.getElementsByClassName('subredditLogo')[0];
        const subredditLogoLink = document.getElementsByClassName('subredditLogoLink')[0];
        const subredditLogoText = document.getElementsByClassName('subredditLogoText')[0];
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9_]{1,19}[a-zA-Z0-9]$/;

        if (!regex.test(subreddit)) {
            toast.dismiss();
            toast('Subreddit must be 3 - 21 characters.\nAlphanumeric and underscores only. Cannot start or end with an underscore.', { type: 'error' });
            event.preventDefault();
            return;
        }
        if (event)
            event.preventDefault();

        fetch(`https://api.reddit.com/r/${subreddit}/about.json`)
            .then(response => response.json())
            .then(data => {
                subredditLogo.src = data?.data?.community_icon ? data?.data?.community_icon?.substring(0, data.data.community_icon.lastIndexOf('.') + 4) : data?.data?.icon_img ? data?.data?.icon_img : '/reddit-logo.png';
                subredditLogoLink.title = data.data.public_description;
                subredditLogoText.innerText = data.data.display_name_prefixed;
            })
            .catch(error => {
                toast.dismiss();
                toast(`Subreddit not found, banned, or reddit service issue. May also be caused by browser tracking protection.`, { type: 'error' });
            });

        fetch(`https://api.reddit.com/r/${subreddit}/hot.json?limit=10&count=10`)
            .then(response => response.json())
            // sometimes the above fetch will still get more than 10 postIds, this slice below guards against that
            .then(data => {
                setPosts(data?.data.children.slice(0, 10).map(child => ({ postId: child.data.id })));
                subredditLogoLink.href = `https://www.reddit.com/r/${subreddit}/`;
                toast.dismiss();
                toast('Subreddit loaded!', { type: 'success' });
            })
            .catch(error => {
                toast.dismiss();
                toast(`Subreddit not found, banned, or reddit service issue. May also be caused by browser tracking protection.`, { type: 'error' });
            });
    }

    // Function for handling favourite button clicks
    function handleFavourite(postId) {
        const index = favouriteIds.findIndex(fav => fav.postId === postId);
        if (index === -1) {
            setFavourites([...favouriteIds, { postId: postId }]);
        } else {
            const newFavourites = [...favouriteIds];
            newFavourites.splice(index, 1);
            setFavourites(newFavourites);
        }
    }

    // Function for handling remove all favourites button click
    function handleRemoveAll() {
        const confirmed = window.confirm('Are you sure you want to remove all your favourites?');
        if (confirmed) {
            setFavourites([]);
        }
    }

    // JSX for rendering the component
    return (
        <div className="RedditFavApp">
            <Toaster
                toastOptions={{
                    style: {
                        background: '#333',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        icon: '👍',
                    },
                    error: {
                        duration: 10000,
                        icon: '🚫',
                    },
                }} />
            <header className="RedditFavApp-header">
                <h2>Reddit Favourites App</h2>
                <label>Subreddit:</label>&nbsp;
                <input
                    type="text"
                    id="subreddit"
                    name="subreddit"
                    defaultValue=""
                    autoFocus
                    onChange={(event) => {
                        handleSubmit(event, event.target.value);
                    }}
                /><br /><br />
                <a className='subredditLogoLink' href='reddit.com' target="_blank" rel="noreferrer">
                    <img className='subredditLogo' alt='Subreddit Logo'></img>&nbsp;
                    <span className='subredditLogoText'></span>
                </a>
                <ol>
                    {/* Mapping over postIds state array and rendering each post as an <li> */}
                    {postIds.map(post => (
                        <PostItem
                            key={post.postId}
                            postId={post.postId}
                            handleFavourite={handleFavourite}
                            favouriteIds={favouriteIds}
                        />
                    ))}
                </ol>
                <h3>Favourites ({favouriteIds.length}) {favouriteIds.length > 0 && (
                    <button onClick={handleRemoveAll}>Remove all favourites <img src="/icon-unfav.png" alt="Button" /> </button>
                )}</h3>
                <ul style={{ listStyleType: 'none' }}>
                    {/* Mapping over favouriteIds state array and rendering each favourite as an <li> */}
                    {favouriteIds.map(fav => (
                        <PostItem
                            key={fav.postId}
                            postId={fav.postId}
                            handleFavourite={handleFavourite}
                            favouriteIds={favouriteIds}
                        />
                    ))}
                </ul>
            </header>
        </div>
    );
}

export default RedditFavApp;
