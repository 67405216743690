import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RedditFavApp from './RedditFavApp';
import { inject } from '@vercel/analytics';
 
inject();

const subredditInputFormRoot = ReactDOM.createRoot(document.getElementById('RedditFavApp'));
subredditInputFormRoot.render(
    <RedditFavApp />
);