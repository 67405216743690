/*
*	FILE			:	PostItem.js
*	PROJECT			:	SENG3080 - A1
*	PROGRAMMER		:	Andrew Gordon
*	DESCRIPTION		:
*		Contains the PostItem component used get the post details from a post ID and return it
*/

import React, { useState, useEffect } from 'react';

/**
 * @description The PostItem is used to get the title, score, and permalink from a post ID
 * @param {Object} props -  key - key used for mapping
                            postId - id of the post being queried
                            handleFavourite - function used to add/remove from the favourites list
                            favouriteIds - the favourite posts ids
 * @returns {JSX.Element} - The component JSX.Element contains the nicely formatted post information
 */
function PostItem(props) {
    const [postDetails, setPost] = useState(null);

    useEffect(() => {
        // used to prevent setting state after component unmounts
        let isMounted = true;

        fetchPostDetails(props.postId)
            .then((data) => {
                if (isMounted) {
                    setPost(data);
                }
            })
            .catch((error) => console.log(`Error fetching post details: ${error.message}`));

        return () => {
            isMounted = false;
        };
    }, [props.postId]);

    function fetchPostDetails(postId) {
        const endpoint = `https://api.reddit.com/api/info.json?id=t3_${postId}`;
        return fetch(endpoint)
            .then(response => response.json())
            .then(data => data.data.children[0].data)
    }

    // handle the favourite/unfavourite button click
    function handleClick() {
        props.handleFavourite(props.postId);
    }

    // if postDetails is null, show a loading message
    if (!postDetails) {
        return (
            <li>
                <span><i>Loading post details...</i></span>
            </li>
        );
    }

    // check if the post is already favourited
    const isFavourited = props.favouriteIds.findIndex((fav) => fav.postId === props.postId) !== -1;

    const imgLoc = (!postDetails.post_hint || postDetails.post_hint === 'link' || postDetails.post_hint === 'image' || postDetails.post_hint === 'rich:video') && (postDetails.thumbnail !== 'default' && postDetails.thumbnail !== 'self' && postDetails.thumbnail !== 'nsfw' && postDetails.thumbnail !== 'image') ? postDetails.thumbnail : '/reddit-logo.png';

    return (
        <li className='postItemList'>
            <span title={`${postDetails?.selftext ?? ''}`}>
                <a href={`https://reddit.com${postDetails.permalink}`} target="_blank" rel="noopener noreferrer">
                    <img id="thumbnail" src={imgLoc} alt="Post" height="50" width="50" />&nbsp;
                    {postDetails.title}&nbsp;
                    (Score: {postDetails.score})&nbsp;
                </a>
            </span>
            <button onClick={handleClick} className="favourite-button">
                {isFavourited ? (
                    <img
                        className='favIcon'
                        src="/icon-unfav.png"
                        alt="Unfavourite Button"
                        title="Click to unfavourite this post"
                    />
                ) : (
                    <img
                        className='favIcon'
                        src="/icon-fav.png"
                        alt="Favourite Button"
                        title="Click to favourite this post"
                    />
                )}
            </button>
        </li>
    );
}

export default PostItem;
